import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

export default function CouponsList() {
  const [coupons, setCoupons] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const token = localStorage.getItem("token");

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [selectedCoupon, setSelectedCoupon] = useState("");

  const fetchCoupons = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/get-all-coupons`,
        {
          headers: { token: token },
        }
      );
      console.log(response.data);
      setCoupons(response.data.coupons);
    } catch (error) {
      alert("Session Expired");
      localStorage.clear();
      // Redirect to login if session expired
      // navigate("/login");
    }
  };

  const deleteCoupon = async (couponId) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}admin/delete-coupon/${couponId}`,
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchCoupons();
  }, [callApi]);

  const couponsData = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 150,
      },
      {
        label: "Minimum Order Value",
        field: "minPrice",
        sort: "asc",
        width: 150,
      },
      {
        label: "Discount",
        field: "discount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: coupons?.map((coupon, index) => {
      return {
        s_no: <td>{index + 1}.</td>,
        title: coupon.title,
        description: coupon.description,
        minPrice: <td>{coupon.minPrice}</td>,
        discount: <td><span style={{textTransform: "uppercase"}}>{coupon.discount.discountType}</span> &nbsp;{coupon.discount.value}</td>,
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            {/* {service.active ? (
              <span style={{ color: "green" }}>Active</span>
            ) : (
              <span style={{ color: "red" }}>Inactive</span>
            )} */}

            <Link to="/edit-coupon" state={{ Coupon: coupon }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>

            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Coupon?`,
                  type: "delete",
                });
                setSelectedCoupon({ id: coupon._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Coupons List"}></Breadcrumb>
            {/* Add a button to add coupons if needed */}
            <Link to="/add-coupon">
              <Button className="btn btn-primary">ADD Coupon</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={couponsData}
                          style={{
                            marginTop: "1rem",
                            width:"100%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>

      {/* Warning Coupon Modal */}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "30%",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={5}
          >
            {warning.text}
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();

                if (warning.type === "delete") {
                  deleteCoupon(selectedCoupon.id);
                  setOpenWarning(false);
                }
              }}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-success"
            >
              Yes
            </button>
            <button
              onClick={() => setOpenWarning(false)}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-danger"
            >
              No
            </button>
          </div>
        </Sheet>
      </Modal>
    </PageLayout>
  );
}
