import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { CardHeader } from "../../../components/cards";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function EditService() {
  const location = useLocation();

  const navigate = useNavigate();

  let Service;
  if (location.state && location.state.Service) {
    Service = location.state.Service;
  }

  const [serviceTypes, setServiceTypes] = useState([]);

  const [service, setService] = useState({
    title: Service.title,
    content: Service.content,
    type: Service.type,
    placeholder: Service.placeholder,
    logo: Service.logo, // To store the uploaded logo file
  });

  const [uploadingLogo, setUploadingLogo] = useState(false);

  const [uploadedLogoUrl, setUploadedLogoUrl] = useState(Service.logo);

  // Function to upload a logo to S3
  const uploadLogo = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}upload`, // Replace with your S3 upload endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.fileUrl; // Assuming the response contains the file URL
    } catch (error) {
      console.error("Error uploading logo:", error);
      throw error;
    }
  };

  // Function to handle file input change
  const handleLogoChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        setUploadingLogo(true);
        const logoUrl = await uploadLogo(selectedFile);
        setUploadedLogoUrl(logoUrl);
        setService({ ...service, logo: selectedFile }); // Store the selected file in the state
      } catch (error) {
        console.error("Error uploading logo:", error);
      } finally {
        setUploadingLogo(false);
      }
    }
  };

  const editService = async () => {
    try {
      // Validation checks
      if (
        !service.title ||
        // !service.content ||
        !service.type ||
        !service.logo
      ) {
        alert("Please fill in all fields and upload a logo.");
        return;
      }

      // Create the request body as JSON
      const requestBody = {
        title: service.title,
        content: service.content,
        type: service.type,
        placeholder: service.placeholder,
        logo: uploadedLogoUrl, // Use the URL of the uploaded logo
      };

      // Upload the service data
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}service/edit-service/${Service._id}`,
        requestBody,
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "application/json", // Send data as JSON
          },
        }
      );

      alert("Service Edited Successfully!");
      navigate("/link-store");
    } catch (error) {
      console.error("Error editing service:", error);
      alert("Error adding service. Please try again.");
    }
  };

  const getServiceTypes = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}admin/get-service-types`)
      .then((res) => {
        setServiceTypes(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getServiceTypes();
  }, []);


  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Edit Service"}></Breadcrumb>
            <Link to={`/link-store`}>
              <Button className="btn btn-primary">Service List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <CardHeader title="Service Title" />
                <TextField
                  id="outlined-required"
                  value={service.title}
                  placeholder="Enter Service Title"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setService({ ...service, title: e.target.value })
                  }
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Content" />
                <TextField
                  id="outlined-multiline"
                  value={service.content}
                  multiline
                  placeholder="Enter Service Content"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setService({ ...service, content: e.target.value })
                  }
                />
              </Col>


              <Col xl={3}>
                <CardHeader title="Service Type" />
                <TextField
                  id="outlined-select-service"
                  select
                  value={service.type}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setService({ ...service, type: e.target.value })
                  }
                >
                  {serviceTypes.map((serviceType) => (
                    <MenuItem key={serviceType._id} value={serviceType}>
                      {serviceType}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>


              {/* <Col xl={3}>
                <CardHeader title="Service Type" />
                <TextField
                  id="outlined-required"
                  value={service.type}
                  placeholder="Enter Service Type"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setService({ ...service, type: e.target.value })
                  }
                />
              </Col> */}

              <Col xl={3}>
                <CardHeader title="Service Placeholder" />
                <TextField
                  id="outlined-required"
                  value={service.placeholder}
                  placeholder="Enter Service Placeholder"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setService({ ...service, placeholder: e.target.value })
                  }
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Upload Logo" />
                <input
                  id="logo"
                  type="file"
                  accept="image/*"
                  sx={{ width: "100%" }}
                  onChange={handleLogoChange}
                />
              </Col>
            </Row>
          </Box>
        </Col>

        {uploadedLogoUrl && (
          <Col
            xl={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <div>
              <CardHeader title="Selected Logo" />
              <img
                src={uploadedLogoUrl}
                alt="Selected Logo"
                style={{ maxWidth: "150px" }}
              />
            </div>
          </Col>
        )}

        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              onClick={editService}
              disabled={uploadingLogo}
            >
              {uploadingLogo ? "Editing..." : "Edit Service"}
            </Button>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
