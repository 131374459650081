import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

export default function OrdersList() {
  const [orders, setOrders] = useState([]);
  const [teams, setTeams] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const token = localStorage.getItem("token");

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState("");

  const [selectedStatus, setSelectedStatus] = useState(0);

  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/get-all-orders`,
        {
          headers: { token: token },
        }
      );
      console.log(response.data);
      setOrders(response.data);
    } catch (error) {
      alert("Session Expired");
      localStorage.clear();
      // Redirect to login if session expired
      navigate("/login");
    }
  };

  const changePayStatus = async (id) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}admin/change-pay-status/${id}`,
        {},
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeDelDate = async (id, deliveryDate) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}admin/change-delivery-date/${id}`,
        {deliveryDate: deliveryDate},
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeDelStatus = async (id) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}admin/change-delivery-status/${id}`,
        { status: selectedStatus },
        {
          headers: { token: token },
        }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //   const deleteTeam = async (teamId) => {
  //     await axios
  //       .delete(
  //         `${process.env.REACT_APP_BASE_URL}admin/delete-team/${teamId}`,
  //         { headers: { token: token } }
  //       )
  //       .then((res) => {
  //         console.log(res.data);
  //         setCallApi(!callApi);
  //       })
  //       .catch((e) => {
  //         alert(e.response.data.message);
  //       });
  //   };

  useEffect(() => {
    fetchOrders();
  }, [callApi]);

  const orderData = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Order Id",
        field: "orderId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Txn Id",
        field: "txnId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Products",
        field: "products",
        sort: "asc",
        width: 150,
      },
      {
        label: "Billing Address",
        field: "address",
        sort: "asc",
        width: 150,
      },
      {
        label: "Custom Order",
        field: "custom",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Amount",
        field: "total",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment Mode",
        field: "mode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment Status",
        field: "payStatus",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Status",
        field: "delStatus",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Date",
        field: "orderDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Delivery Date",
        field: "delDate",
        sort: "asc",
        width: 150,
      },
    ],
    rows: orders?.map((order, index) => {
      const curDate = new Date();
      return {
        s_no: <td>{index + 1}.</td>,
        orderId: order.orderId,
        txnId: order.txnId,
        products: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {order.products?.map((product, index) => {
              console.log(product);
              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  key={product._id}
                >
                  <img
                    style={{ width: "4rem", height: "4rem" }}
                    src={product._id?.images[0]}
                  ></img>
                  <td>{product._id?.title}</td>
                  <td style={{ fontWeight: 500 }}>
                    &nbsp;({product._id?.price} x {product?.quantity})
                  </td>
                </div>
              );
            })}
          </div>
        ),
        address: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <td>{order.address?.name}</td>
            <td>{order.address?.mobile}</td>
            <td>{order.address?.email}</td>
            <td>{order.address?.address}</td>
          </div>
        ),
        total: order.totalAmt.toFixed(2),
        mode: order.paymentMode,
        payStatus: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedOrder({ id: order._id });
                setWarning({
                  text: `Do you want to change the payment status to ${
                    order.paymentStatus === false ? `Paid?` : `Not Paid?`
                  }`,
                  type: "pay",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={
                order.paymentStatus === false
                  ? `btn btn-danger`
                  : `btn btn-success`
              }
            >
              {order.paymentStatus === false ? `Not Paid` : `Paid`}
            </button>
          </div>
        ),
        delStatus: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <select
              value={order.status}
              onChange={(e) => {
                const newStatus = parseInt(e.target.value);
                setOpenWarning(true);
                setSelectedOrder({ id: order._id });
                setSelectedStatus(parseInt(e.target.value));
                let warningText = "";
                switch (newStatus) {
                  case 0:
                    warningText =
                      "Do you want to change the order status to Pending?";
                    break;
                  case 1:
                    warningText =
                      "Do you want to change the order status to Processing?";
                    break;
                  case 2:
                    warningText =
                      "Do you want to change the order status to Delivered?";
                    break;
                  case -1:
                    warningText =
                      "Do you want to change the order status to Failed?";
                    break;
                  default:
                    warningText = "Do you want to change the order status?";
                    break;
                }
                setWarning({ text: warningText, type: "del" });
              }}
            >
              <option value={0}>Pending</option>
              <option value={1}>Processing</option>
              <option value={2}>Delivered</option>
              <option value={-1}>Failed</option>
            </select>
            {/* <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedOrder({ id: order._id });
                setWarning({
                  text: `Do you want to change the delivery status to ${
                    order.deliveredStatus === false
                      ? `Delivered?`
                      : `Not Delivered?`
                  }`,
                  type: "del",
                });
              }}
              style={{
                width: "6rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "0.5rem",
              }}
              className={
                order.deliveredStatus === false
                  ? "btn btn-danger"
                  : "btn btn-success"
              }
            >
              {order.deliveredStatus === false ? `Not Delivered` : `Delivered`}
            </button> */}
          </div>
        ),
        delDate: (
          <>
            <input
              onChange={(e) => changeDelDate(order._id, e.target.value)}
              type="date"
              min={moment(curDate).format("YYYY-MM-DD")}
              value={
                order.deliveryDate&& moment(order.deliveryDate).format("YYYY-MM-DD")
              }
            ></input>
          </>
        ),
        orderDate: moment(order.createdAt).format("DD/MM/YYYY"),
        custom: (
          <>
            {order.products?.map((el) => {
              return (
                <div style={{ margin: "0.6rem" }}>
                  {el.custom?.image && (
                    <a
                      href={el.custom.image}
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Download Image
                    </a>
                  )}
                  {el.custom?.text && (
                    <p style={{ marginTop: "1rem" }}>
                      <span style={{ fontWeight: "bold" }}>Text</span> -{" "}
                      {el.custom.text}
                    </p>
                  )}
                </div>
              );
            })}
          </>
        ),

        // actions: (
        //   <td
        //     style={{
        //       display: "flex",
        //       justifyContent: "space-evenly",
        //       width: "100%",
        //     }}
        //   >
        //     <button
        //       type="button"
        //       onClick={() => {
        //         setOpenWarning(true);
        //         setWarning({
        //           text: `Do you want to delete the Team?`,
        //           type: "delete",
        //         });
        //         setSelectedTeam({ id: team._id });
        //       }}
        //       style={{
        //         width: "2rem",
        //         fontSize: "0.6rem",
        //         display: "flex",
        //         justifyContent: "center",
        //         alignItems: "center",
        //       }}
        //       className="btn btn-danger"
        //     >
        //       <i className="fas fa-trash"></i>
        //     </button>
        //   </td>
        // ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Orders List"}></Breadcrumb>
            {/* Add a button to add teams if needed */}
            {/* <Link to="/add-coupon">
              <Button className="btn btn-primary">ADD Coupon</Button>
            </Link> */}
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={orderData}
                          style={{
                            marginTop: "1rem",
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>

      {/* Warning Coupon Modal */}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "30%",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={5}
          >
            {warning.text}
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();

                // if (warning.type === "delete") {
                //   deleteTeam(selectedTeam.id);
                //   setOpenWarning(false);
                // }
                if (warning.type === "pay") {
                  changePayStatus(selectedOrder.id);
                  setOpenWarning(false);
                }
                if (warning.type === "del") {
                  changeDelStatus(selectedOrder.id);
                  setOpenWarning(false);
                }
              }}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-success"
            >
              Yes
            </button>
            <button
              onClick={() => setOpenWarning(false)}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-danger"
            >
              No
            </button>
          </div>
        </Sheet>
      </Modal>
    </PageLayout>
  );
}
