import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { CardHeader } from "../../../components/cards";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function EditCoupon() {
  const location = useLocation();

  const navigate = useNavigate();

  let Coupon;
  if (location.state && location.state.Coupon) {
    Coupon = location.state.Coupon;
  }

  // Function to format ISO date to yyyy-MM-dd format
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [coupon, setCoupon] = useState({
    title: Coupon.title,
    description: Coupon.description,
    minPrice: Coupon.minPrice,
    discount: Coupon.discount.value,
    discountType: Coupon.discount.discountType,
    startDate: formatDate(Coupon.startDate), // Format start date
    endDate: formatDate(Coupon.endDate), // Format end date
  });

  const editCoupon = async () => {
    try {
      // Validation checks
      if (
        !coupon.title ||
        // !coupon.description ||
        // !coupon.minPrice ||
        !coupon.discount
      ) {
        alert("Please fill in title and discount amount");
        return;
      }

      // Create the request body as JSON
      const requestBody = {
        title: coupon.title,
        description: coupon.description,
        minPrice: coupon.minPrice,
        discount: {
          discountType: coupon.discountType,
          value: coupon.discount,
        },
        startDate: coupon.startDate,
        endDate: coupon.endDate,
      };

      // Upload the service data
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/edit-coupon/${Coupon._id}`,
        requestBody,
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "application/json", // Send data as JSON
          },
        }
      );

      alert("Coupon Edited Successfully!");
      navigate("/coupons-list");
    } catch (error) {
      console.error("Error editing coupon:", error);
      alert("Error adding coupon. Please try again.");
    }
  };

  // Function to get today's date in "yyyy-MM-dd" format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let day = today.getDate();
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Edit Coupon"}></Breadcrumb>
            <Link to={`/coupons-list`}>
              <Button className="btn btn-primary">Coupons List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <CardHeader title="Coupon Title" />
                <TextField
                  id="outlined-required"
                  value={coupon.title}
                  placeholder="Enter Coupon Title"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setCoupon({ ...coupon, title: e.target.value })
                  }
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Description" />
                <TextField
                  id="outlined-multiline"
                  value={coupon.description}
                  multiline
                  placeholder="Enter Coupon Content"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setCoupon({ ...coupon, description: e.target.value })
                  }
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Minimum Order Value" />
                <TextField
                  type="number"
                  id="outlined-select-service"
                  value={coupon.minPrice}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setCoupon({ ...coupon, minPrice: e.target.value })
                  }
                ></TextField>
              </Col>

              {/* Coupon Discount */}
              <Col xl={3}>
                <CardHeader title="Coupon Discount" />
                <Row>
                  <Col xl={6}>
                    <TextField
                      type="number"
                      id="outlined-required"
                      value={coupon.discount}
                      placeholder="Enter Discount"
                      sx={{ width: "100%" }}
                      onChange={(e) =>
                        setCoupon({ ...coupon, discount: e.target.value })
                      }
                    />
                  </Col>
                  <Col xl={6}>
                    <TextField
                      select
                      value={coupon.discountType}
                      onChange={(e) =>
                        setCoupon({
                          ...coupon,
                          discountType: e.target.value,
                        })
                      }
                      variant="outlined"
                    >
                      <MenuItem value="percentage">%</MenuItem>
                      <MenuItem value="fixed">Fixed</MenuItem>
                    </TextField>
                  </Col>
                </Row>
              </Col>

              {/* Start Date */}
              <Col xl={3}>
                <CardHeader title="Start Date" />
                <TextField
                  type="date"
                  value={coupon.startDate}
                  onChange={(e) =>
                    setCoupon({ ...coupon, startDate: e.target.value })
                  }
                  sx={{ width: "100%" }}
                  // Set min attribute to today's date
                  InputProps={{ inputProps: { min: getTodayDate() } }}
                />
              </Col>

              {/* End Date */}
              <Col xl={3}>
                <CardHeader title="End Date" />
                <TextField
                  type="date"
                  value={coupon.endDate}
                  onChange={(e) =>
                    setCoupon({ ...coupon, endDate: e.target.value })
                  }
                  sx={{ width: "100%" }}
                  // Set min attribute to today's date
                  InputProps={{ inputProps: { min: getTodayDate() } }}
                />
              </Col>
            </Row>
          </Box>
        </Col>

        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button className="btn btn-primary" onClick={editCoupon}>
              Edit Coupon
            </Button>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
