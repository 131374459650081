import React, { useState } from "react";
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
  Text,
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/forgot.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const [passwords, setPasswords] = useState({ oldPass: "", newPass: "" });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "confirmPassword") {
      setConfirmPassword(value);
    } else {
      setPasswords({
        ...passwords,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwords.newPass !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (passwords.newPass == passwords.oldPass) {
      setError("New Password cannot be same as Old Password");
      return;
    }

    // Replace with your API endpoint
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}admin/reset-password`, passwords)
      .then((res) => {
        console.log(res.data);
        navigate("/login");
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  return (
    <Box className="mc-auth">
      <Image
        className="mc-auth-pattern"
        src={data?.pattern.src}
        alt={data?.pattern.alt}
      />
      <Box className="mc-auth-group">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <Form className="mc-auth-form">
          {data?.input.map((item, index) => (
            <IconField
              key={index}
              icon={item.icon}
              type={item.type}
              classes={item.fieldSize}
              placeholder={item.placeholder}
              passwordVisible={true}
              required={true}
              name={index === 0 ? "oldPass" : "newPass"}
              onChange={handleInputChange}
            />
          ))}
          <IconField
            icon="password"
            type="password"
            classes="h-sm"
            placeholder="Confirm New Password"
            passwordVisible={true}
            required={true}
            name="confirmPassword"
            onChange={handleInputChange}
          />
          {error && <Text className="mc-alert red">{error}</Text>}
          <Button
            onClick={handleSubmit}
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
          >
            {data?.button.text}
          </Button>
        </Form>

        <Box className="mc-auth-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
        </Box>
      </Box>
    </Box>
  );
}
