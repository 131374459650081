import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../../components";
import { CardHeader } from "../../../components/cards";
import { Box, Item, Anchor, Text, Button} from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
// import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function PlanList() {
  const [plans, setPlans] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const featureOptions = [
    "Theme",
    "Gain Leads",
    "Quick Intro",
    "Custom Branding",
    "Hello Direct",
    // Add more feature options as needed
  ];

  const deletePlan = async (planId) => {
    await axios
      .delete(`${process.env.REACT_APP_BASE_URL}admin/delete-plan/${planId}`, {
        headers: { token },
      })
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const changeStatus = async (board_id, active) => {
    console.log(active);
    let FinalStatus;
    if (active === 0) {
      FinalStatus = 1;
    } else {
      FinalStatus = 0;
    }
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}board/change-status`, {
        id: board_id,
        active: FinalStatus,
      })
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const fetchPlans = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}admin/get-all-plans`, {
        headers: { token: token },
      })
      .then((res) => {
        console.log(res.data);
        setPlans(res.data);
      })
      .catch((e) => {
        alert("Session Expired");
        localStorage.clear();
        navigate("/login");
      });
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const openEditModal = (plan) => {
    setSelectedPlan(plan);
    setSelectedFeatures(plan.features);
    setShowEditModal(true);
  };

  const handleFeatureChange = (event) => {
    setSelectedFeatures(event.target.value);
  };

  const updateFeatures = async () => {
    if (selectedPlan) {
      editPlanFeatures();
    }
  };

  const editPlanFeatures = async () => {
    try {
      // Create the request body as JSON
      const requestBody = {
        features: selectedFeatures,
      };

      // Upload the plan data
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/edit-plan/${selectedPlan._id}`,
        requestBody,
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "application/json", // Send data as JSON
          },
        }
      );
      setCallApi(!callApi);
      alert("Plan Edited Successfully!");
      setShowEditModal(false);
    } catch (error) {
      console.error("Error adding plan:", error);
      alert("Error editing plan. Please try again.");
    }
  };

  useEffect(() => {
    fetchPlans();
  }, [callApi]);

  const plans_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Plan Name",
        field: "title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Plan Mrp",
        field: "mrp",
        sort: "asc",
        width: 150,
      },
      {
        label: "Plan Monthly Price",
        field: "monthlyPrice",
        sort: "asc",
        width: 150,
      },
      {
        label: "Plan Yearly Price",
        field: "yearlyPrice",
        sort: "asc",
        width: 150,
      },
      {
        label: "Plan Duration",
        field: "duration",
        sort: "asc",
        width: 150,
      },
      {
        label: "Features",
        field: "features",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: plans?.map((plan, index) => {
      return {
        s_no: <td>{index + 1}.</td>,
        title: plan.title,
        mrp: plan.mrp,
        monthlyPrice: plan.monthlyPrice,
        yearlyPrice: plan.yearlyPrice,
        duration: (
          <td style={{ textTransform: "capitalize" }}>{plan.duration}</td>
        ),
        features: (
          <td
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div
              style={{
                width: "2.5rem",
                height: "2.5rem",
                borderRadius: "50%",
                color: "#fff",
                background: "#ff6b6b",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                openEditModal(plan);
                setSelectedPlan(plan);
              }}
            >
              {plan.features.length}
            </div>
          </td>
        ),
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <Link to="/edit-plan" state={{ Plan: plan }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => deletePlan(plan._id)}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  console.log(plans_data);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Subscriptions List"}></Breadcrumb>
             <Link to={`/add-plan`}>
              <Button className="btn btn-primary">Add Subscription</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Plans List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={plans_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>

      {/* Edit Features Modal */}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "50%",
            minHeight: "20rem",
            display: "flex",
            flexDirection: "column", // Display children in a column layout
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={5}
          >
            Edit Features
          </Typography>

          <FormControl fullWidth>
            <InputLabel id="feature-select-label">Select Features</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              label="Select Features"
              multiple
              fullWidth
              value={selectedFeatures}
              onChange={handleFeatureChange}
              renderValue={(selected) => (
                <div>
                  {selected.join(", ")}{" "}
                  {/* Join selected features with commas */}
                </div>
              )}
            >
              {featureOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Spacer to push buttons to the bottom */}
          <div style={{ flex: 1 }}></div>

          <Stack spacing={2} p={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between", // Align buttons to the end
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={updateFeatures}
              >
                Save
              </Button>
            </div>
          </Stack>
        </Sheet>
      </Modal>
    </PageLayout>
  );
}
