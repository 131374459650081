import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Button,
  Image,
  Input,
  Text,
  Box,
  Icon,
  Anchor,
  Option,
  Heading,
} from "../elements";
import userInfo from "../../data/master/userList.json";

export default function UsersTable({ thead, tbody, customers }) {
  const [data, setData] = useState([]);
  const [userData, setUserData] = React.useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;

    if (name === "allCheck") {
      const checkData = data?.map((item) => {
        return { ...item, isChecked: checked };
      });
      setData(checkData);
    } else {
      const checkData = data?.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item
      );
      setData(checkData);
    }
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Input
                  type="checkbox"
                  name="allCheck"
                  checked={
                    data?.filter((item) => item.isChecked !== true).length < 1
                  }
                  onChange={handleCheckbox}
                />
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {customers?.map((item, index) => (
            <Tr key={item.id}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>{item.id}</Text>
                </Box>
              </Td>
              <Td title={item.name}>
                <Box className="mc-table-profile">
                  <Image
                    src={
                      item.profileImg ? item.profileImg : "/images/logo.png"
                    }
                    alt={item.alt}
                  />
                  <Text>{item.name}</Text>
                </Box>
              </Td>
              <Td title={item.email}>{item.email}</Td>
              <Td title={item.status}>
                {item.active === 1 && (
                  <Text className="mc-table-badge green">Active</Text>
                )}
                {item.active === 0 && (
                  <Text className="mc-table-badge red">Inactive</Text>
                )}
              </Td>

              <Td title={item.status}>
                {item.is_streamer === "NA" && (
                  <Text className="mc-table-badge purple">Not Applied</Text>
                )}
                {item.is_streamer === "approved" && (
                  <Text className="mc-table-badge green">approved</Text>
                )}
                {item.is_streamer === "pending" && (
                  <Text className="mc-table-badge purple">pending</Text>
                )}
                {item.is_streamer === "blocked" && (
                  <Text className="mc-table-badge red">blocked</Text>
                )}
              </Td>

              <Td>
                <Box className="mc-table-action">
                  <Button
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => setEditModal(true, setUserData(item))}
                  >
                    edit
                  </Button>
                  <Button
                    title="Block"
                    className="material-icons block"
                    onClick={() => setBlockModal(true)}
                  >
                    block
                  </Button>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false, setUserData(""))}
      >
        <Box className="mc-user-modal">
          <Image src={userData.src} alt={userData?.alt} />
          <Heading as="h4">{userData?.name}</Heading>
          <Text as="p">{userData?.email}</Text>
          <Form.Group className="form-group inline mb-4">
            <Form.Label>status</Form.Label>
            <Form.Select>
              <Option>{userData?.active === 1 ? "Active" : "Inactive"}</Option>
              {userInfo.status.map((item, index) =>
                item !== userData.active ? (
                  <Option key={index} value={item}>
                    {item === 1 ? "Active" : "Inactive"}
                  </Option>
                ) : null
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group inline">
            <Form.Label style={{ marginRight: "4rem" }}>
              streamer status
            </Form.Label>
            <Form.Select>
              <Option>{userData?.is_streamer}</Option>
              {userInfo.streamer_status.map((item, index) =>
                item !== userData.is_streamer ? (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ) : null
              )}
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setEditModal(false)}
            >
              close popup
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              onClick={() => setEditModal(false)}
            >
              save Changes
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to block this user's account?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setBlockModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setBlockModal(false)}
            >
              yes, block
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}
