import React, { useState } from "react";
import { Box, Form, Heading, Button, Anchor, Image, Text, Input, Select } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import Axios from 'axios';


import TextField from '@mui/material/TextField';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Link, Navigate, useNavigate } from "react-router-dom";
import SketchPicker, { BlockPicker } from 'react-color';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function Login() {

    const navigate = useNavigate();

    const [admin, setAdmin] = useState({
        email : '',
        password : ''
    })

    const [type, setType] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const Login = async () => {
        // if(type === "admin"){

        // }else if(type === "teacher"){

        // }
        await Axios.post(`${process.env.REACT_APP_BASE_URL}admin/login`,{
            email,password
        })
        .then((res) => {
            console.log(res.data);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('admin', JSON.stringify(res.data.Admin));
            navigate('/')
        }).catch((e) => {
            alert(e.response.data.message);
        })
    }   

    return (
        <Box className="mc-auth" style={{width:"100%",height:"100vh",display:"flex",alignItems:"center"}}>
            <Image
                src={ data?.pattern.src } 
                alt={ data?.pattern.alt }
                className="mc-auth-pattern"  
            />
            <Box className="mc-auth-group">
                <Logo 
                    src = { data?.logo.src }
                    alt = { data?.logo.alt }
                    href = { data?.logo.path }
                    className = "mc-auth-logo"
                />
                <Heading as="h4" className="mc-auth-title">{ data?.title }</Heading>
                <Form className="mc-auth-form" >
                     <TextField
                       id="filled-basic" 
                       label="Email"
                       type="text"
                       variant="filled"
                       sx={{width:"100%",mb:"1.5rem"}}
                       onChange={(e) => setEmail(e.target.value)}
                     />

                    <TextField
                       id="filled-basic" 
                       label="Password"
                       type="password"
                       variant="filled"
                       sx={{width:"100%", mb:"1.5rem"}}
                       onChange={(e) => setPassword(e.target.value)}
                     />
                    
                    {/* <div>
                    <FormControl sx={{width:"100%"}}>
                      <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Grade"
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>11</MenuItem>
                        
                      </Select>
                    </FormControl>
                    </div> */}
                    
                    <Button onClick={Login} className={`mc-auth-btn ${data?.button.fieldSize}`} type={ data?.button.type }>{ data?.button.text }</Button>
                    {/* <Anchor className="mc-auth-forgot" href={ data?.forgot.path }>{ data?.forgot.text }</Anchor> */}
                </Form>
            </Box>
        </Box>
    );
}