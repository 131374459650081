import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import Axios from "axios";
import { useNavigate } from "react-router-dom";


export default function StudentsList() {
  const [openViewStudent, setOpenViewStudent] = useState(false);
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [warning, setWarning] = useState({ text: "", status: "" });
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showCardsModal, setShowCardsModal] = useState(false);
  const [selectedUserCards, setSelectedUserCards] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [openCardWarning, setOpenCardWarning] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);

  const navigate = useNavigate();

  const fetchUsers = async () => {
    await Axios.get(`${process.env.REACT_APP_BASE_URL}admin/get-all-users`, {
      headers: { token: token },
    })
      .then((res) => {
        console.log(res.data);
        setUsers(res.data);
      })
      .catch((e) => {
        alert("Session Expired");
        localStorage.clear();
        navigate("/login");
        setUsers([]);
      });
  };

  const changeStatus = async (userId) => {
    await Axios.patch(
      `${process.env.REACT_APP_BASE_URL}admin/change-user-status/${userId}`,
      { headers: { token: token } }
    )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const deleteUser = async (userId) => {
    await Axios.delete(
      `${process.env.REACT_APP_BASE_URL}admin/delete-user/${userId}`,
      { headers: { token: token } }
    )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const deleteCard = async (userId, cardId) => {
    await Axios.post(
      `${process.env.REACT_APP_BASE_URL}admin/delete-card`,
      { card_id: cardId, userId: userId },
      { headers: { token: token } }
    )
      .then((res) => {
        console.log(res.data);
        setSelectedUserCards(res.data.cards); // Update the cards after deletion
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const user_data = {
    columns: [
      { label: "S.no", field: "s_no", sort: "asc", width: 150 },
      { label: "Name", field: "name", sort: "asc", width: 150 },
      { label: "Email", field: "email", sort: "asc", width: 150 },
      { label: "Phone", field: "phone", sort: "asc", width: 200 },
      { label: "Designation", field: "designation", sort: "asc", width: 100 },
      { label: "Bio", field: "bio", sort: "asc", width: 150 },
      { label: "Account Type", field: "type", sort: "asc", width: 150 },
      { label: "Plan", field: "plan", sort: "asc", width: 100 },
      { label: "Cards", field: "cards", sort: "asc", width: 100 },
      { label: "Actions", field: "actions", sort: "asc", width: 150 },
    ],
    rows: users?.map((user, index) => {
      return {
        s_no: (
          <td style={{ display: "flex" }}>
            {index + 1}. &nbsp;
            <div
              style={{
                width: "4rem",
                height: "4rem",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <img style={{ width: "100%" }} src={user.profileImg}></img>
            </div>
          </td>
        ),
        name: user.name,
        email: user.email,
        phone: user.phone,
        designation: user.designation,
        bio: user.bio,
        type: <p style={{ textTransform: "capitalize", fontWeight: "bold" }}>{user.type}</p>,
        plan: (
          <>
            {!user.plan ? (
              <Button className="btn btn-primary">Free</Button>
            ) : (
              <Button
                className="btn btn-success"
                onClick={() => {
                  setSelectedPlan(user.plan);
                  setShowEditModal(true);
                }}
              >
                {user.plan.title}
              </Button>
            )}
          </>
        ),
        cards: (
          <Button
            className="btn btn-info"
            onClick={() => {
              setSelectedUser(user);
              setSelectedUserCards(user.cards);
              setShowCardsModal(true);
            }}
          >
            View Cards
          </Button>
        ),
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedUser({ id: user._id });
                setWarning({
                  text: `Do you want to change the status to ${
                    user.active === false ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                user.active === false ? `btn btn-danger` : `btn btn-success`
              }
            >
              {user.active === false ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the User?`,
                  type: "delete",
                });
                setSelectedUser({ id: user._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  useEffect(() => {
    fetchUsers();
  }, [callApi]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={"Users List"}></Breadcrumb>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Users List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={user_data}
                          style={{
                            marginTop: "1rem",
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Warning User Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedUser.id);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteUser(selectedUser.id);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>

        {/* Edit Features Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              minHeight: "20rem",
              display: "flex",
              flexDirection: "column", // Display children in a column layout
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {selectedPlan?.title} Features
            </Typography>

            <ul style={{ listStyleType: "circle", marginLeft: "1rem" }}>
              {selectedPlan?.features?.map((el) => {
                return <li>{el}</li>;
              })}
            </ul>

            {/* Spacer to push buttons to the bottom */}
            <div style={{ flex: 1 }}></div>

            <Stack spacing={2} p={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between", // Align buttons to the end
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </Stack>
          </Sheet>
        </Modal>

        {/* Cards Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={showCardsModal}
          onClose={() => setShowCardsModal(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              minHeight: "20rem",
              display: "flex",
              flexDirection: "column",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              style={{ textTransform: "capitalize" }}
              mb={5}
            >
              {selectedUser?.name}'s Cards
            </Typography>

            <ul style={{ listStyleType: "none", padding: 0 }}>
              {selectedUserCards?.length > 0 ? 
              selectedUserCards.map((card) => (
                <li
                  key={card}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0.5rem 0",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <span>{card}</span>
                  <Button
                    style={{ background: "red", border: "none" }}
                    onClick={() => {
                      setOpenCardWarning(true);
                      setCardToDelete(card);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </li>
              ))
              :
              <p style={{textAlign:"center", marginTop:"3rem", fontWeight:"bold"}}>User does not have any Connected Devices.</p>
            }
            </ul>

            <Stack spacing={2} p={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowCardsModal(false)}
                >
                  Close
                </Button>
              </div>
            </Stack>
          </Sheet>
        </Modal>

        {/* Confirm Delete Card Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openCardWarning}
          onClose={() => setOpenCardWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              Do you want to delete this card?
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  deleteCard(selectedUser._id, cardToDelete);
                  setOpenCardWarning(false);
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenCardWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
