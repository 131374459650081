import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

export default function CategoryList() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const [openEditCategory, setOpenEditCategory] = useState(false);

  const [editedCategory, setEditedCategory] = useState({
    id: "",
    title: "",
    description: "",
  });

  const token = localStorage.getItem("token");

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/get-all-categories`,
        {
          headers: { token: token },
        }
      );
      console.log(response.data);
      setCategories(response.data.categories);
    } catch (error) {
      alert("Session Expired");
      localStorage.clear();
      // Redirect to login if session expired
      // navigate("/login");
    }
  };

  const changeStatus = async (categoryId) => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}admin/toggle-category/${categoryId}`,
        {},
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const deleteCategory = async (categoryId) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}admin/delete-category/${categoryId}`,
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const editCategory = async () => {
    try {
      // Validation checks
      if (!editedCategory.title) {
        alert("Please provide Title !");
        return;
      }

      // Upload the category data
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/edit-category/${editedCategory.id}`,
        editedCategory,
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );

      alert("Category Edited Successfully!");
      setCallApi(!callApi);
      setOpenEditCategory(false);
    } catch (error) {
      console.error("Error editing category:", error);
      alert("Error editing category. Please try again.");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [callApi]);

  const categoriesData = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: categories?.map((category, index) => {
      return {
        s_no: <td>{index + 1}.</td>,
        title: category.title,
        description: category.description,
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            {/* {category.active ? (
              <span style={{ color: "green" }}>Active</span>
            ) : (
              <span style={{ color: "red" }}>Inactive</span>
            )} */}

            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedCategory({ id: category._id });
                setWarning({
                  text: `Do you want to change the status to ${
                    category.active === false ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                category.active === false ? `btn btn-danger` : `btn btn-success`
              }
            >
              {category.active === false ? `Inactive` : `Active`}
            </button>

            <button
              type="button"
              onClick={() => {
                setOpenEditCategory(true);
                setEditedCategory({
                  title: category.title,
                  id: category._id,
                  description: category.description,
                });
              }}
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              <i className="fas fa-edit"></i>
            </button>

            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Category?`,
                  type: "delete",
                });
                setSelectedCategory({ id: category._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Categories List"}></Breadcrumb>
            {/* Add a button to add categories if needed */}
            {/* <Link to="/add-category">
              <Button>ADD Service</Button>
            </Link> */}
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={categoriesData}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>

      {/* Warning Category Modal */}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "30%",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={5}
          >
            {warning.text}
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                if (warning.type === "status") {
                  changeStatus(selectedCategory.id);
                  setOpenWarning(false);
                } else if (warning.type === "delete") {
                  deleteCategory(selectedCategory.id);
                  setOpenWarning(false);
                }
              }}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-success"
            >
              Yes
            </button>
            <button
              onClick={() => setOpenWarning(false)}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-danger"
            >
              No
            </button>
          </div>
        </Sheet>
      </Modal>

      {/* Edit Category Modal */}
      <Modal
        aria-labelledby="modal-title-edit"
        aria-describedby="modal-desc-edit"
        open={openEditCategory}
        onClose={() => setOpenEditCategory(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "50%",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={5}
          >
            Edit {selectedCategory.title}Category
          </Typography>

          <Stack spacing={2} sx={{ alignItems: "center" }}>
            <TextField
              id="outlined-required"
              defaultValue=""
              placeholder="Enter Category Title ..."
              multiline
              label="Category Title"
              value={editedCategory.title}
              onChange={(e) =>
                setEditedCategory({ ...editedCategory, title: e.target.value })
              }
              sx={{ width: "100%" }}
            />
            <TextField
              id="outlined-required"
              defaultValue=""
              placeholder="Enter Category Description ..."
              multiline
              label="Category Description"
              value={editedCategory.description}
              onChange={(e) =>
                setEditedCategory({
                  ...editedCategory,
                  description: e.target.value,
                })
              }
              sx={{ width: "100%" }}
            />
          </Stack>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <button
              disabled={editedCategory ? false : true}
              onClick={editCategory}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </Sheet>
      </Modal>
    </PageLayout>
  );
}
