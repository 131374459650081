import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { CardHeader } from "../../../components/cards";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function EditPlan() {
  const location = useLocation();

  const navigate = useNavigate();

  let Plan;
  if (location.state && location.state.Plan) {
    Plan = location.state.Plan;
  }

  const [plan, setPlan] = useState({
    title: Plan.title,
    mrp: Plan.mrp,
    monthlyPrice: Plan.monthlyPrice,
    yearlyPrice: Plan.yearlyPrice,
    duration: Plan.duration,
    shortBenefits: Plan.shortBenefits,
    benefits: Plan.benefits,
  });

  const [uploadingImage, setUploadingImage] = useState(false);

  // Function to upload an image to S3
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}upload`, // Replace with your S3 upload endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.fileUrl; // Assuming the response contains the file URL
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  // Corrected function names
  const handleShortBenefitImageChange = (index, event) => {
    const updatedShortBenefits = [...plan.shortBenefits];
    const file = event.target.files[0];

    if (file) {
      uploadImage(file)
        .then((imageUrl) => {
          updatedShortBenefits[index].image = imageUrl;
          setPlan({ ...plan, shortBenefits: updatedShortBenefits });
        })
        .catch((error) => {
          console.error("Error uploading short benefit image:", error);
        });
    }
  };

  const handleBenefitImageChange = (index, event) => {
    const updatedBenefits = [...plan.benefits];
    const file = event.target.files[0];

    if (file) {
      uploadImage(file)
        .then((imageUrl) => {
          updatedBenefits[index].image = imageUrl;
          setPlan({ ...plan, benefits: updatedBenefits });
        })
        .catch((error) => {
          console.error("Error uploading benefit image:", error);
        });
    }
  };

  const editPlan = async () => {
    try {
      setUploadingImage(true);

      // Validation checks
      if (
        !plan.title ||
        !plan.mrp ||
        !plan.monthlyPrice ||
        !plan.yearlyPrice ||
        !plan.duration ||
        plan.shortBenefits.length === 0 ||
        plan.benefits.length === 0
      ) {
        alert(
          "Please fill in all fields and add at least one short benefit and one benefit."
        );
        return;
      }

      // Create the request body as JSON
      const requestBody = {
        title: plan.title,
        mrp: plan.mrp,
        monthlyPrice: plan.monthlyPrice,
        yearlyPrice: plan.yearlyPrice,
        duration: plan.duration,
        shortBenefits: plan.shortBenefits,
        benefits: plan.benefits,
      };

      // Upload the plan data
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/edit-plan/${Plan._id}`,
        requestBody,
        {
          headers: {
            token: localStorage.getItem("token"),
            "Content-Type": "application/json", // Send data as JSON
          },
        }
      );

      alert("Plan Edited Successfully!");
      navigate("/plan-list");
    } catch (error) {
      console.error("Error adding plan:", error);
      alert("Error editing plan. Please try again.");
    } finally {
      setUploadingImage(false);
    }
  };

  const handleShortBenefitsChange = (index, field, value) => {
    const updatedShortBenefits = [...plan.shortBenefits];
    updatedShortBenefits[index][field] = value;
    setPlan({ ...plan, shortBenefits: updatedShortBenefits });
  };

  const handleBenefitsChange = (index, field, value) => {
    const updatedBenefits = [...plan.benefits];
    updatedBenefits[index][field] = value;
    setPlan({ ...plan, benefits: updatedBenefits });
  };

  const addShortBenefit = () => {
    if (plan.shortBenefits.every((benefit) => benefit.title)) {
      setPlan({
        ...plan,
        shortBenefits: [...plan.shortBenefits, { title: "", image: "" }],
      });
    } else {
      alert("Please fill in the current Short Benefit fields.");
    }
  };

  const addBenefit = () => {
    if (
      plan.benefits.every(
        (benefit) => benefit.title && benefit.description
      )
    ) {
      setPlan({
        ...plan,
        benefits: [...plan.benefits, { title: "", description: "", image: "" }],
      });
    } else {
      alert("Please fill in the current Benefit fields.");
    }
  };

  const removeShortBenefit = (index) => {
    if (plan.shortBenefits.length > 1) {
      const updatedShortBenefits = [...plan.shortBenefits];
      updatedShortBenefits.splice(index, 1);
      setPlan({ ...plan, shortBenefits: updatedShortBenefits });
    }
  };

  const removeBenefit = (index) => {
    const updatedBenefits = [...plan.benefits];
    updatedBenefits.splice(index, 1);
    setPlan({ ...plan, benefits: updatedBenefits });
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Plan"}></Breadcrumb>
            <Link to={`/plan-list`}>
              <Button className="btn btn-primary">Plans List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <CardHeader title="Plan Title" />
                <TextField
                  id="outlined-required"
                  value={plan.title}
                  placeholder="Enter Plan Title"
                  sx={{ width: "100%" }}
                  onChange={(e) => setPlan({ ...plan, title: e.target.value })}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="MRP" />
                <TextField
                  id="outlined-required"
                  value={plan.mrp}
                  placeholder="Enter MRP"
                  sx={{ width: "100%" }}
                  onChange={(e) => setPlan({ ...plan, mrp: e.target.value })}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Monthly Price" />
                <TextField
                  id="outlined-required"
                  value={plan.monthlyPrice}
                  type="number"
                  placeholder="Enter Monthly Price"
                  sx={{ width: "100%" }}
                  onChange={(e) => setPlan({ ...plan, monthlyPrice: parseInt(e.target.value) })}
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Yearly Price" />
                <TextField
                  id="outlined-required"
                  value={plan.yearlyPrice}
                  type="number"
                  placeholder="Enter Yearly Price"
                  sx={{ width: "100%" }}
                  onChange={(e) => setPlan({ ...plan, yearlyPrice: parseInt(e.target.value)})}
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Duration" />
                <TextField
                  id="outlined-required"
                  select
                  value={plan.duration}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setPlan({ ...plan, duration: e.target.value })
                  }
                >
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="year">Year</MenuItem>
                </TextField>
              </Col>
            </Row>
          </Box>
        </Col>
        <Box className="mc-card">
          <Col xl={12}>
            <Box className="mc-card mb-4">
              <CardHeader title={`Add Short Benefits`} />
              {plan.shortBenefits.map((shortBenefit, index) => (
                <Col xl={12} key={`shortBenefit-${index}`}>
                  <CardHeader title={`Short Benefit ${index + 1}`} />
                  <TextField
                    id={`shortBenefit-title-${index}`}
                    multiline
                    value={shortBenefit.title}
                    placeholder={`Enter Short Benefit ${index + 1}`}
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    onChange={(e) =>
                      handleShortBenefitsChange(index, "title", e.target.value)
                    }
                  />
                  <input
                    id={`shortBenefit-image-${index}`}
                    type="file"
                    accept="image/*"
                    sx={{ width: "100%" }}
                    onChange={(e) => handleShortBenefitImageChange(index, e)}
                  />
                  {shortBenefit.image && (
                    <img
                      src={shortBenefit.image}
                      alt={`Short Benefit ${index + 1}`}
                      style={{ maxWidth: "10rem", objectFit: "cover" }}
                    />
                  )}
                  <div>
                    <Button
                      className="btn btn-danger mt-4 mb-4"
                      onClick={() => removeShortBenefit(index)}
                    >
                      Remove ShortBenefit
                    </Button>
                  </div>
                </Col>
              ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "3rem",
                }}
              >
                <Button className="btn btn-primary" onClick={addShortBenefit}>
                  <i className="fas fa-plus"></i>&nbsp;Add Short Benefit
                </Button>
              </div>
            </Box>
          </Col>
          <Col xl={12}>
            <Box className="mc-card">
              <CardHeader title={`Add Benefits`} />
              {plan.benefits.map((benefit, index) => (
                <Col xl={12} key={`benefit-${index}`}>
                  <CardHeader title={`Benefit ${index + 1}`} />
                  <TextField
                    id={`benefit-title-${index}`}
                    value={benefit.title}
                    multiline
                    placeholder={`Enter Benefit ${index + 1} Title`}
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    onChange={(e) =>
                      handleBenefitsChange(index, "title", e.target.value)
                    }
                  />
                  <TextField
                    id={`benefit-description-${index}`}
                    value={benefit.description}
                    placeholder={`Enter Benefit ${index + 1} Description`}
                    sx={{ width: "100%", marginBottom: "1rem" }}
                    onChange={(e) =>
                      handleBenefitsChange(index, "description", e.target.value)
                    }
                  />
                  <input
                    id={`benefit-image-${index}`}
                    type="file"
                    accept="image/*"
                    sx={{ width: "100%" }}
                    onChange={(e) => handleBenefitImageChange(index, e)}
                  />
                  {benefit.image && (
                    <img
                      src={benefit.image}
                      alt={`Benefit ${index + 1}`}
                      style={{ maxWidth: "10rem", objectFit: "cover" }}
                    />
                  )}
                  <div>
                    <Button
                      className="btn btn-danger mt-4 mb-4"
                      onClick={() => removeBenefit(index)}
                    >
                      Remove Benefit
                    </Button>
                  </div>
                </Col>
              ))}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "3rem",
                }}
              >
                <Button className="btn btn-primary" onClick={addBenefit}>
                  <i className="fas fa-plus"></i>&nbsp;Add Benefit
                </Button>
              </div>
            </Box>
          </Col>
          <Col xl={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "3.25rem",
              }}
            >
              <Button
                className="btn btn-primary"
                onClick={editPlan}
                disabled={uploadingImage}
              >
                {uploadingImage ? "Editing..." : "Edit Plan"}
              </Button>
            </div>
          </Col>
        </Box>
      </Row>
    </PageLayout>
  );
}
