import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

export default function ServicesList() {
  const [services, setServices] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const token = localStorage.getItem("token");

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [selectedService, setSelectedService] = useState("");

  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/get-all-services`,
        {
          headers: { token: token },
        }
      );
      console.log(response.data);
      setServices(response.data.services);
    } catch (error) {
      alert("Session Expired");
      localStorage.clear();
      // Redirect to login if session expired
      // navigate("/login");
    }
  };

  const changeStatus = async (serviceId) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}admin/toggle-active-service/${serviceId}`,
        {},
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const togglePro = async (serviceId) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}admin/toggle-pro-service/${serviceId}`,
        {},
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const toggleProPlus = async (serviceId) => {
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}admin/toggle-proplus-service/${serviceId}`,
        {},
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const deleteService = async (serviceId) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}service/delete-service/${serviceId}`,
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchServices();
  }, [callApi]);

  const servicesData = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Content",
        field: "content",
        sort: "asc",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Placeholder",
        field: "placeholder",
        sort: "asc",
        width: 150,
      },
      {
        label: "Logo",
        field: "logo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: services?.map((service, index) => {
      return {
        s_no: <td>{index + 1}.</td>,
        title: service.title,
        content: service.content,
        type: <td>{service.type}</td>,
        placeholder: <td>{service.placeholder}</td>,
        logo: (
          <img
            src={service.logo}
            alt={service.title}
            style={{ width: "50px" }}
          />
        ),
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            {/* {service.active ? (
              <span style={{ color: "green" }}>Active</span>
            ) : (
              <span style={{ color: "red" }}>Inactive</span>
            )} */}

            {/* Activity Toggle >>>>> */}
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedService({ id: service._id });
                setWarning({
                  text: `Do you want to change the status to ${
                    service.active === false ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                service.active === false ? `btn btn-danger` : `btn btn-success`
              }
            >
              {service.active === false ? `Inactive` : `Active`}
            </button>
            {/* <<<<< Activity Toggle */}

            {/* PRO STATUS TOGGLE */}
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedService({ id: service._id });
                setWarning({
                  text: `Do you want to change the service status to ${
                    service.isPro === false ? `Pro?` : `Free?`
                  }`,
                  type: "pro",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                service.isPro === false ? `btn btn-primary` : `btn btn-success`
              }
            >
              {service.isPro === false ? `Free` : `Pro`}
            </button>
            {/* PRO STATUS TOGGLE */}

            {/* PRO PLUS STATUS TOGGLE */}
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedService({ id: service._id });
                setWarning({
                  text: `Do you want to change the service status to ${
                    service.isProPlus === false ? `Pro Plus?` : `Free?`
                  }`,
                  type: "proPlus",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                service.isProPlus === false
                  ? `btn btn-secondary`
                  : `btn btn-success`
              }
            >
              {service.isProPlus === false ? `Free` : `Pro Plus`}
            </button>
            {/* PRO PLUS STATUS TOGGLE */}


            <Link to="/edit-service" state={{ Service: service }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>

            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Service?`,
                  type: "delete",
                });
                setSelectedService({ id: service._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Services List"}></Breadcrumb>
            {/* Add a button to add services if needed */}
            <Link to="/add-service">
              <Button className="btn btn-primary">ADD Service</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={servicesData}
                          style={{
                            marginTop: "1rem",
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>

      {/* Warning Service Modal */}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "30%",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={5}
          >
            {warning.text}
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                if (warning.type === "status") {
                  changeStatus(selectedService.id);
                  setOpenWarning(false);
                } 
                else if (warning.type === "pro") {
                  togglePro(selectedService.id);
                  setOpenWarning(false);
                }
                else if (warning.type === "proPlus") {
                  toggleProPlus(selectedService.id);
                  setOpenWarning(false);
                }
                else if (warning.type === "delete") {
                  deleteService(selectedService.id);
                  setOpenWarning(false);
                }
              }}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-success"
            >
              Yes
            </button>
            <button
              onClick={() => setOpenWarning(false)}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-danger"
            >
              No
            </button>
          </div>
        </Sheet>
      </Modal>
    </PageLayout>
  );
}
