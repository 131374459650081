import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { CardHeader } from "../../../components/cards";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";

export default function AddCategory() {
  const navigate = useNavigate();

  const [category, setCategory] = useState({
    title: "",
    description: "",
  });


  const AddCategory = async () => {
    try {
      // Validation checks
      if (
        !category.title
      ) {
        alert("Please provide Title !");
        return;
      }

      // Upload the category data
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}admin/add-category`,
        category,
        {
          headers: {
            token: localStorage.getItem("token")
          },
        }
      );

      alert("Category Added Successfully!");
      navigate("/category-list");
    } catch (error) {
      console.error("Error adding category:", error);
      alert("Error adding category. Please try again.");
    }
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Add Category"}></Breadcrumb>
            <Link to={`/category-list`}>
              <Button className="btn btn-primary">Category List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <CardHeader title="Category Title" />
                <TextField
                  id="outlined-required"
                  value={category.title}
                  placeholder="Enter Category Title"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setCategory({ ...category, title: e.target.value })
                  }
                />
              </Col>

              <Col xl={3}>
                <CardHeader title="Description" />
                <TextField
                  id="outlined-multiline"
                  value={category.description}
                  multiline
                  placeholder="Enter Category Description"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setCategory({ ...category, description: e.target.value })
                  }
                />
              </Col>
            </Row>
          </Box>
        </Col>

        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              className="btn btn-primary"
              onClick={AddCategory}
            >
              Add Category
            </Button>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
