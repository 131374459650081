import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { CardHeader } from "../../../components/cards";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Link } from "react-router-dom";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";


export default function ProductList() {
  const [products, setProducts] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const token = localStorage.getItem("token");

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    fetchProducts();
  }, [callApi]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/get-all-products`,
        {
          headers: { token: token },
        }
      );

      setProducts(response.data.products);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const changeStatus = async (productId) => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}admin/toggle-product/${productId}`,
        {},
        { headers: { token: token } }
      )
      .then((res) => {
        console.log(res.data);
        setCallApi(!callApi);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const deleteProduct = async (productId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}admin/delete-product/${productId}`,
        {
          headers: { token },
        }
      );
      setCallApi(!callApi);
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Error deleting product. Please try again.");
    }
  };

  const products_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "productName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Categories",
        field: "categories",
        sort: "asc",
        width: 150,
      },
      {
        label: "MRP",
        field: "mrp",
        sort: "asc",
        width: 150,
      },
      {
        label: "Discount",
        field: "discount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 150,
      },
      {
        label: "Quantity",
        field: "quantity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: products?.map((product, index) => {
      return {
        s_no: <td>{index + 1}.</td>,
        productName: (
          <>
            <img
              src={product.images[0]}
              style={{ width: "6rem", height: "6rem", objectFit: "cover" }}
            />
            &nbsp; <span>{product.title}</span>
          </>
        ),
        categories: (
          <>
            {product.categories.map((cat, index) => (
              <span key={cat._id}>
                {cat.title}
                {index < product.categories.length - 1 ? ", " : ""}
              </span>
            ))}
          </>
        ),
        mrp: product.mrp,
        discount: product.discount,
        price: product.price,
        quantity: product.quantity,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedProduct({ id: product._id });
                setWarning({
                  text: `Do you want to change the status to ${
                    product.active === false ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                product.active === false ? `btn btn-danger` : `btn btn-success`
              }
            >
              {product.active === false ? `Inactive` : `Active`}
            </button>

            <Link to={`/edit-product`} state={{ Product: product }}>
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Product?`,
                  type: "delete",
                });
                setSelectedProduct({ id: product._id });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Product List"}></Breadcrumb>
            <Link to={`/add-product`}>
              <Button className="btn btn-primary">Add Product</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Product List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={products_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>

      {/* Warning Category Modal */}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: "30%",
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            bgcolor: "#fff",
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "#eee",
            }}
          />
          <Typography
            id="basic-modal-dialog-title"
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb={5}
          >
            {warning.text}
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                if (warning.type === "status") {
                  changeStatus(selectedProduct.id);
                  setOpenWarning(false);
                } else if (warning.type === "delete") {
                  deleteProduct(selectedProduct.id);
                  setOpenWarning(false);
                }
              }}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-success"
            >
              Yes
            </button>
            <button
              onClick={() => setOpenWarning(false)}
              type="button"
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "0.5rem",
              }}
              className="btn btn-danger"
            >
              No
            </button>
          </div>
        </Sheet>
      </Modal>
    </PageLayout>
  );
}
