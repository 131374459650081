import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { CardHeader } from "../../../components/cards";
import { Box, Button } from "../../../components/elements";
import PageLayout from "../../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import CKEditor from "react-ckeditor-component";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Select } from "@mui/material";

export default function EditProduct() {
  const location = useLocation();

  const navigate = useNavigate();

  let Product;
  if (location.state && location.state.Product) {
    Product = location.state.Product;
  }
  const token = localStorage.getItem("token");

  const [primaryImage, setPrimaryImage] = useState("");
  const [primaryIndex, setPrimaryIndex] = useState(null);

  console.log(Product);

  const [product, setProduct] = useState({
    title: Product.title,
    description: Product.description,
    categories: Product.categories?.map((category) => {
      return category._id;
    }),
    mrp: Product.mrp,
    price: Product.price,
    discount: Product.discount,
    compatibility: Product.compatibility,
    returnPolicy: Product.returnPolicy,
    images: Product.images, // Array to store image URLs
    quantity: Product.quantity,
  });

  const [categories, setCategories] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}admin/get-all-categories`,
          { headers: { token: token } }
        );
        setCategories(response.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [token]);

  const handleDescriptionChange = (event) => {
    const newContent = event.editor.getData();
    setProduct((prevProduct) => ({
      ...prevProduct,
      description: newContent,
    }));
  };

  const handleImageChange = async (e) => {
    try {
      const files = e.target.files;

      // Convert FileList to an array
      const fileList = Array.from(files);

      // Upload each selected image one by one
      for (const imageFile of fileList) {
        const imageUrl = await uploadImage(imageFile);
        setProduct((prevProduct) => ({
          ...prevProduct,
          images: [...prevProduct.images, imageUrl],
        }));
      }

      // Clear the selected images
      setSelectedImages([]);
    } catch (error) {
      console.error("Error handling images:", error);
      alert("Error handling images. Please try again.");
    }
  };

  // Function to upload an image to S3
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}upload`, // Replace with your S3 upload endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.fileUrl; // Assuming the response contains the file URL
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleDeleteImage = (index) => {
    setProduct((prevProduct) => {
      const updatedImages = [...prevProduct.images];
      updatedImages.splice(index, 1);

      return { ...prevProduct, images: updatedImages };
    });
  };

  const editProduct = async () => {
    try {
      // Validation checks
      if (!product.title || product.categories.length === 0) {
        alert("Please provide Title and Category!");
        return;
      }

      // Upload the product data
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}admin/edit-product/${Product._id}`,
        { ...product, primaryImg: primaryImage },
        {
          headers: {
            token: token,
          },
        }
      );
      console.log(response.data);
      alert("Product Edited Successfully!");
      navigate("/product-list");
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Error adding product. Please try again.");
    }
  };

  const handlePrimaryImageChange = (index) => {
    const primaryImage = product.images[index];
    setPrimaryImage(primaryImage);
    setPrimaryIndex(0);
    setProduct((prevProduct) => ({
      ...prevProduct,
      images: [
        primaryImage,
        ...prevProduct.images.filter(
          (img, idx) => idx !== index && img !== primaryImage
        ),
      ],
    }));
  };

  useEffect(() => {
    setProduct({ ...product, discount: product.mrp - product.price });
  }, [product.mrp, product.price]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Edit Product"}></Breadcrumb>
            <Link to={`/product-list`}>
              <Button className="btn btn-primary">Product List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <CardHeader title="Product Title" />
                <TextField
                  id="outlined-required"
                  value={product.title}
                  placeholder="Enter Product Title"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setProduct({ ...product, title: e.target.value })
                  }
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Category" />
                <Select
                  id="outlined-select-category"
                  value={product.categories}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setProduct({ ...product, categories: e.target.value })
                  }
                  multiple // Enable multi-select
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <div key={value} style={{ marginRight: 5 }}>
                          {
                            categories.find((category) => category._id == value)
                              ?.title
                          }
                        </div>
                      ))}
                    </div>
                  )}
                >
                  {categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      <Checkbox
                        checked={product.categories.includes(category._id)}
                      />
                      {category.title}
                    </MenuItem>
                  ))}
                </Select>
              </Col>

              <Col xl={2}>
                <CardHeader title="Product MRP" />
                <TextField
                  type="number"
                  id="outlined-required"
                  value={product.mrp}
                  placeholder="Enter MRP"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setProduct({ ...product, mrp: e.target.value })
                  }
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Product Discount" />
                <TextField
                  type="number"
                  id="outlined-required"
                  value={product.discount}
                  placeholder="Enter Discount"
                  sx={{ width: "100%" }}
                  disabled
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Product Price" />
                <TextField
                  type="number"
                  id="outlined-required"
                  value={product.price}
                  placeholder="Enter Price"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setProduct({ ...product, price: e.target.value })
                  }
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Product Quantity" />
                <TextField
                  type="number"
                  id="outlined-required"
                  value={product.quantity}
                  placeholder="Enter Quantity"
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setProduct({ ...product, quantity: e.target.value })
                  }
                />
              </Col>

              <Col xl={6}>
                <CardHeader title="Product Compatibility" />
                <TextField
                  type="text"
                  id="outlined-required"
                  value={product.compatibility}
                  placeholder="Type Here..."
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setProduct({ ...product, compatibility: e.target.value })
                  }
                  multiline
                />
              </Col>

              <Col xl={6}>
                <CardHeader title="Product Return Policy" />
                <TextField
                  type="text"
                  id="outlined-required"
                  value={product.returnPolicy}
                  placeholder="Type Here..."
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setProduct({ ...product, returnPolicy: e.target.value })
                  }
                  multiline
                />
              </Col>

              <Col xl={12}>
                <CardHeader title="Description" />
                <CKEditor
                  content={product.description}
                  events={{ change: handleDescriptionChange }}
                />
              </Col>

              <Col xl={12}>
                <CardHeader title="Product Images" />
                <input
                  key={product.images.length}
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                />
                {product.images.length > 0 && (
                  <div className="mt-2">
                    <p>Selected Images:</p>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "start",
                      }}
                    >
                      {product.images.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            width: "calc(33.33% - 1rem)",
                            marginBottom: "1rem",
                            marginRight: "1rem",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                                marginBottom: "0.5rem",
                              }}
                              src={image}
                              alt={`Image ${index}`}
                            />
                            {primaryIndex === index ? (
                              <input
                                type="radio"
                                style={{
                                  position: "absolute",
                                  top: "1rem",
                                  right: "1rem",
                                  transform: "translate(50%, -50%)",
                                  cursor: "pointer",
                                }}
                                name="primary"
                                checked={true}
                                readOnly
                              />
                            ) : (
                              <input
                                type="radio"
                                style={{
                                  position: "absolute",
                                  top: "1rem",
                                  right: "1rem",
                                  transform: "translate(50%, -50%)",
                                  cursor: "pointer",
                                }}
                                name="primary"
                                onChange={() => handlePrimaryImageChange(index)}
                              />
                            )}

                            <button
                              className="btn btn-danger"
                              onClick={() => handleDeleteImage(index)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Box>
        </Col>

        <Col xl={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button className="btn btn-primary" onClick={editProduct}>
              Edit Product
            </Button>
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
